import type { IQuitPlanRiskSituation } from "@/interfaces/IQuitPlanRiskSituation";
import type { IQuitPlanWithdrawalSyndrome } from "@/interfaces/IQuitPlanWithdrawalSyndrom";
import type { IQuitPlanRelapse } from "@/interfaces/IQuitPlanRelapse";
import type IMileStone from "@/interfaces/IMileStone";
import type IAppointment from "@/interfaces/IAppointment";

export const sortRiskSituationAndWithdrawalSyndromes = (
  a: IQuitPlanRiskSituation | IQuitPlanWithdrawalSyndrome,
  b: IQuitPlanRiskSituation | IQuitPlanWithdrawalSyndrome
) => {
  const isCurrent = (b.isCurrent ? 1 : 0) - (a.isCurrent ? 1 : 0);
  const aDate = new Date(a.datetime);
  const bDate = new Date(b.datetime);
  if (a.id && b.id) {
    return (
      isCurrent ||
      b.datetime.localeCompare(a.datetime) ||
      bDate.getMilliseconds() - aDate.getMilliseconds()
    );
  } else if (a.id) {
    return isCurrent || -1;
  } else if (b.id) {
    return isCurrent || 1;
  }
  return (
    isCurrent ||
    a.datetime.localeCompare(b.datetime) ||
    aDate.getMilliseconds() - bDate.getMilliseconds()
  );
};

export const sortRelapses = (a: IQuitPlanRelapse, b: IQuitPlanRelapse) => {
  const aDate = new Date(a.datetime);
  const bDate = new Date(b.datetime);
  if (a.id && b.id) {
    return (
      b.datetime.localeCompare(a.datetime) ||
      bDate.getMilliseconds() - aDate.getMilliseconds()
    );
  } else if (a.id) {
    return -1;
  } else if (b.id) {
    return 1;
  }
  return (
    a.datetime.localeCompare(b.datetime) ||
    aDate.getMilliseconds() - bDate.getMilliseconds()
  );
};

// ! duplicate in client-frontend!
export const sortMilestones = (milestones: IMileStone[]) => {
  const today = new Date().getTime();
  const active: IMileStone[] = milestones.filter(
    (m: IMileStone) => m.isCurrent
  );
  const inActive: IMileStone[] = milestones.filter(
    (m: IMileStone) => !m.isCurrent
  );

  sortActiveMilestones(active, today);
  sortInactiveMilestones(inActive, today);
  const merged = [...active, ...inActive];
  return merged;
};

const getDiffFromToday = (date: string | Date, today: any): number => {
  const newDate = new Date(date).getTime();

  const diffFromToday = Math.abs(today - newDate);
  return diffFromToday;
};

const sortInactiveMilestones = (milestones: IMileStone[], today: any) => {
  return milestones.sort((a: IMileStone, b: IMileStone) => {
    if (!a.exitDate) {
      return 0;
    }

    if (!b.exitDate) {
      return 0;
    }
    const aDiff = getDiffFromToday(a.exitDate, today);
    const bDiff = getDiffFromToday(b.exitDate, today);

    if (aDiff > bDiff) {
      return -1;
    }
    if (aDiff === bDiff) {
      return 0;
    }
    return 1;
  });
};

const sortActiveMilestones = (milestones: IMileStone[], today: any) => {
  return milestones.sort((a: IMileStone, b: IMileStone) => {
    if (!a.exitDate) {
      return 0;
    }

    if (!b.exitDate) {
      return 0;
    }
    const aDiff = getDiffFromToday(a.exitDate, today);
    const bDiff = getDiffFromToday(b.exitDate, today);

    if (aDiff > bDiff) {
      return 1;
    }
    if (aDiff === bDiff) {
      return 0;
    }
    return -1;
  });
};

export const setTimeOnDate = (date: Date, time: string) => {
  const [hours, minutes] = time.split(":");
  date.setHours(parseInt(hours));
  date.setMinutes(parseInt(minutes));
};

export const sortAppointmentsByDate = (
  a: IAppointment,
  b: IAppointment
): number => {
  const aDate = new Date(a.date || "");
  const bDate = new Date(b.date || "");

  if (a.slot?.startTime) {
    setTimeOnDate(aDate, a.slot?.startTime);
  }

  if (b.slot?.startTime) {
    setTimeOnDate(bDate, b.slot?.startTime);
  }

  return aDate.getTime() - bDate.getTime();
};
