import { userApi } from "./api";

/**
 * Gets all tenant information
 *
 * @return The tenant tag
 */
const getTenant = async (tenantTag: string) => {
  const response = await userApi.get(`tenant/tenant/${tenantTag}/`);

  const result = response.data;

  return result;
};

const getTenantLanguages = async () => {
  const response = await userApi.get("tenant/tenant-languages/");

  return response.data;
};

export { getTenant, getTenantLanguages };
