import type { RouteLocation } from "vue-router";

const STORAGE_NAME = "redirectUrl";
/**
 * Provide methods to record a route location when a login redirection happens
 * so that after logging in we can redirect back to that url
 */

export const setRedirectLocation = (route: RouteLocation): void => {
  localStorage.setItem(STORAGE_NAME, JSON.stringify(route));
};

export const getRedirectLocation = (): RouteLocation | null => {
  const redirectUrl = localStorage.getItem(STORAGE_NAME);
  if (!redirectUrl) {
    return null;
  }

  try {
    const redirectPath = JSON.parse(redirectUrl);

    return redirectPath;
  } catch {
    // can't decode the stuff, let's ignore that and clear it
    return null;
  } finally {
    localStorage.removeItem(STORAGE_NAME);
  }
};
