const CounselingOverview = () => import("@/views/CounselingOverviewView.vue");
const DossierView = () => import("@/views/DossierView.vue");
const Login = () => import("@/views/LoginView.vue");
const Profile = () => import("@/views/ProfileView.vue");
const PasswordReset = () => import("@/views/PasswordResetView.vue");
const ForgotPassword = () => import("@/views/ForgotPasswordView.vue");
// const CounselorList = () => import("@/views/CounselorListView.vue");
// const CounselingArchive = () => import("@/views/CounselingArchiveView.vue");
const Availability = () => import("@/views/AvailabilityView.vue");
const Dashboard = () => import("@/views/DashboardView.vue");

import { useAuthStore } from "@/store/Auth";
import type { RouteRecordRaw } from "vue-router";
import { getRedirectLocation } from "@/helpers/redirectUrl";

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    name: "dashboard",
    component: Dashboard,
    beforeEnter: async (to: any, from: any, next: any) => {
      // if there's a redirectPath, let's go there instead
      // to home
      // the path is recorded in the global router.beforeEach
      const redirectPath = getRedirectLocation();
      if (redirectPath) {
        return next(redirectPath);
      }

      return next();
    },
  },
  {
    path: "/counseling/:tab(\\d+)?",
    name: "counselingOverview",
    component: CounselingOverview,
  },
  {
    path: "/dossier/:id?",
    name: "dossier",
    component: DossierView,
  },
  {
    path: "/profile/:id?",
    name: "profile",
    component: Profile,
    props: true,
  },
  {
    path: "/password-reset",
    name: "password-reset",
    component: PasswordReset,
    meta: {
      requiresNoAuth: true,
    },
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: ForgotPassword,
    meta: {
      requiresNoAuth: true,
    },
  },
  // {
  //   path: "/counselors",
  //   name: "counselor-list",
  //   component: CounselorList,
  // },
  // {
  //   path: "/archive/:tab?",
  //   name: "counseling-archive",
  //   component: CounselingArchive,
  // },
  {
    path: "/availability/:tab?",
    name: "availability",
    component: Availability,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    beforeEnter: async (to: any, from: any, next: any) => {
      const authStore = useAuthStore();
      if (authStore.isLoggedIn) {
        next({ name: "dashboard" });
      }
      next();
    },
    meta: {
      requiresNoAuth: true,
    },
  },
  {
    path: "/logout",
    name: "logout",
    component: {},
    beforeEnter: async (to: any, from: any, next: any) => {
      const authStore = useAuthStore();
      next({ name: "login" });

      authStore.removeAuth();
    },
  },
];

export default routes;
