// https://v3-migration.vuejs.org/breaking-changes/events-api.html

import emitter from "tiny-emitter/instance";

export default {
  $on: (...args: any[]) => emitter.on(...args),
  $once: (...args: any[]) => emitter.once(...args),
  $off: (...args: any[]) => emitter.off(...args),
  $emit: (...args: any[]) => emitter.emit(...args),
};

export const EVENT_ANONYMISE_DOSSIER = "anonymiseDossier";
export const EVENT_LOAD_DOSSIER = "loadDossier";
export const EVENT_CANCEL_ANONYMISING = "cancelAnonymising";
export const EVENT_SAVE_ANONYMISED_DOSSIER = "saveAnonymisedDossier";
export const EVENT_SELECT_TAB = "selectTab";

export const EVENT_ACTIVE_GROUP_CHANGED = "activeGroupChanged";
