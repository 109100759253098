<template>
  <label :class="['block', { 'cursor-not-allowed': disabled }]">
    <span
      class="text-sm"
      :class="[
        { 'text-grayTpf-600': disabled },
        { block: isBlock && !helpText },
        { 'justify-between': helpText },
        { flex: isBlock && helpText },
        { 'inline-flex': !isBlock && helpText },
        { 'mr-4': !isBlock },
        { 'is-required': required },
        classLabel,
      ]"
    >
      {{ label }}
      <SvgIcon
        v-if="props.helpText"
        v-tooltip.left="props.helpText"
        name="info-circle"
        class="text-white svg-icon--lg"
      />
    </span>
    <input
      v-model="localValue"
      :type="type"
      :class="[
        'form-input',
        { 'mt-1.5': isBlock },
        classInput,
        { 'is-error': error },
      ]"
      :placeholder="placeholder"
      :disabled="disabled"
      :maxlength="maxLength"
      @blur="blur"
    />
    <slot></slot>
  </label>
</template>

<script lang="ts" setup>
import { computed, type Ref, toValue, type WritableComputedRef } from "vue";

const emit = defineEmits(["update:modelValue", "blur"]);

const props = withDefaults(
  defineProps<{
    label: string;
    modelValue?: string | Ref<string> | number | null;
    type?: string;
    isBlock?: boolean;
    disabled?: boolean;
    placeholder?: string;
    classInput?: string;
    classLabel?: string;
    maxLength?: number;
    required?: boolean;
    error?: boolean;
    helpText?: string;
  }>(),
  {
    type: "text",
    modelValue: null,
    isBlock: false,
    disabled: false,
    placeholder: "",
    classInput: "",
    classLabel: "",
    maxLength: 9000,
    required: false,
    error: false,
    helpText: "",
  },
);

const localValue: WritableComputedRef<string> = computed({
  get() {
    return (toValue(props.modelValue) ?? "") as string;
  },
  set(newValue: string) {
    emit("update:modelValue", newValue);
  },
});

const blur = () => {
  emit("blur");
};
</script>

<style lang="postcss" scoped>
.is-required::after {
  @apply text-error-600;
  content: " *";
}
</style>
