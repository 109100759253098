import { messagingApi } from "@/services/api";
import type IDossierQuitPlanData from "@/interfaces/IDossierQuitPlanData";
import type { IQuitPlanRiskSituation } from "@/interfaces/IQuitPlanRiskSituation";
import type { IQuitPlanWithdrawalSyndrome } from "@/interfaces/IQuitPlanWithdrawalSyndrom";
import type { IQuitPlanRelapse } from "@/interfaces/IQuitPlanRelapse";

const getQuitPlanData = async (
  quitPlanDataId: number
): Promise<IDossierQuitPlanData> => {
  const response = await messagingApi.get(
    `counseling/quit-plan/${quitPlanDataId}/`
  );
  return response.data;
};

const updateQuitPlan = async (
  quitPlanId: number,
  quitPlanData: Partial<IDossierQuitPlanData>
): Promise<IDossierQuitPlanData> => {
  const response = await messagingApi.patch(
    `counseling/quit-plan/${quitPlanId}/`,
    parseQuitPlanDataPayload(quitPlanData)
  );
  return response.data;
};

const parseQuitPlanDataPayload = (
  quitPlanData: Partial<IDossierQuitPlanData>
): Partial<IDossierQuitPlanData> => {
  // remove empty entries
  quitPlanData.riskSituations = quitPlanData.riskSituations?.filter(
    (item: IQuitPlanRiskSituation) => item.description || item.action
  );
  quitPlanData.withdrawalSyndromes = quitPlanData.withdrawalSyndromes?.filter(
    (item: IQuitPlanWithdrawalSyndrome) => item.description || item.action
  );
  quitPlanData.relapses = quitPlanData.relapses?.filter(
    (item: IQuitPlanRelapse) => item.reason || item.action
  );
  return quitPlanData;
};

export { getQuitPlanData, updateQuitPlan, parseQuitPlanDataPayload };
