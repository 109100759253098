<template>
  <DesktopMenuItem
    :tooltip="$t('navigation.dashboard')"
    exact
    target="dashboard"
    icon="dashboard"
    :label="NavigationLabels.labels.value.dashboard"
    :is-nav-open="props.isNavOpen"
    data-testid="nav-dashboard"
  />
  <DesktopMenuItem
    v-if="isNotSecretary"
    :tooltip="NavigationLabels.consultationsLinkLabel.value"
    target="counselingOverview"
    icon="messages"
    :label="NavigationLabels.consultationsLinkLabel.value"
    :is-nav-open="props.isNavOpen"
    data-testid="nav-home"
  >
    <UnreadDialogCounter />
  </DesktopMenuItem>
  <!-- <DesktopMenuItem
    :tooltip="$t('navigation.counselingArchive')"
    target="counseling-archive"
    icon="archive"
    :label="$t('navigation.counselingArchive')"
    :is-nav-open="props.isNavOpen"
    data-testid="nav-archive"
  /> -->
  <DesktopMenuItem
    v-if="isNotSecretary"
    :tooltip="$t('navigation.availability')"
    target="availability"
    icon="clock"
    :label="$t('navigation.availability')"
    :is-nav-open="props.isNavOpen"
    data-testid="nav-availability"
  />
</template>

<script setup lang="ts">
import { computed } from "vue";

import DesktopMenuItem from "@shared/src/components/DesktopMenuItem.vue";

import { useAuthStore } from "@/store/Auth";
import { useNavigationLabels } from "@/composables/NavigationLabels";
import UnreadDialogCounter from "@/components/UnreadDialogCounter.vue";

const authStore = useAuthStore();
const NavigationLabels = useNavigationLabels();

const props = defineProps<{ isNavOpen: boolean }>();

const isNotSecretary = computed(() => {
  return !authStore.isSecretary;
});
</script>
