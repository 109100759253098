<template>
  <Teleport v-if="isOpen" append :to="mountPoint">
    <transition
      enter-active-class="transition-opacity transition-ease-out"
      leave-active-class="transition-opacity transition-ease-in"
      enter-class="opacity-0"
      enter-to-class="opacity-100"
      leave-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        v-if="isOpen"
        class="modal"
        :class="classModal"
        :aria-hidden="isOpen ? 'false' : 'true'"
        @keydown.esc="hideModal"
      >
        <div
          class="modal__backdrop"
          :class="classBackdrop"
          @click="hideModal"
        ></div>

        <div class="modal__container" :class="classContainer">
          <div v-if="hasHeaderSlot" class="modal__header" :class="classHeader">
            <slot name="header"> &nbsp; </slot>
            <button
              v-if="showBtnClose"
              class="modal__btn-close"
              :class="classBtnClose"
              :title="t('general.modalClose')"
              @click="hideModal"
            >
              <SvgIcon
                name="times-light"
                class="text-primary-500 svg-icon--xl"
                :title="t('general.modalClose')"
              />
            </button>
          </div>

          <div class="modal__body" :class="classBody">
            <slot />
          </div>

          <div v-if="hasFooterSlot" class="modal__footer" :class="classFooter">
            <slot name="footer" />
          </div>
        </div>
      </div>
    </transition>
  </Teleport>
</template>

<script lang="ts" setup>
import {
  computed,
  onBeforeUnmount,
  onMounted,
  ref,
  useSlots,
  watch,
  type Ref,
} from "vue";
import { useI18n } from "vue-i18n";

const slots = useSlots();

const { t } = useI18n();

const emit = defineEmits(["closeModal"]);

const props = defineProps({
  /**
   * Specifies if the modal is opened or not
   */
  isOpen: {
    type: Boolean,
    default: false,
    required: true,
  },
  /**
   * Optional class for the modal
   */
  classModal: {
    type: String,
    default: "",
  },
  /**
   * Optional class for the backdrop
   */
  classBackdrop: {
    type: String,
    default: "",
  },
  /**
   * Optional class for the modal container
   */
  classContainer: {
    type: String,
    default: "",
  },
  /**
   * Optional class for the modal header
   */
  classHeader: {
    type: String,
    default: "",
  },
  /**
   * Optional class for the modal body
   */
  classBody: {
    type: String,
    default: "",
  },
  /**
   * Optional class for the modal footer
   */
  classFooter: {
    type: String,
    default: "",
  },
  /**
   * Optional class for the close button
   */
  classBtnClose: {
    type: String,
    default: "",
  },
  /**
   * Specifies where the modal is mounted
   */
  mountPoint: {
    type: String,
    default: "body",
  },
  /**
   * If the close button should be shown
   */
  showBtnClose: {
    type: Boolean,
    default: false,
  },
});

const initiallyFocusedElement: Ref<HTMLElement | null> = ref(null);

const hasFooterSlot = computed(() => !!slots.footer);
const hasHeaderSlot = computed(() => !!slots.header || props.showBtnClose);

watch(
  () => props.isOpen,
  (opened) => {
    if (opened) {
      saveLastActiveFocus();
      bodyLock();
    } else {
      refocusLastActive();
      bodyUnlock();
    }
  },
);

onMounted(() => {
  window.addEventListener("keyup", handleKeyEvent);
});

onBeforeUnmount(() => {
  window.removeEventListener("keyup", handleKeyEvent);
  bodyUnlock();
});

const bodyLock = () => {
  if (!document.body.classList.contains("fixed")) {
    document.body.style.top = `-${window.scrollY}px`;
    document.body.classList.add("fixed", "w-full");
  }
};

const bodyUnlock = () => {
  if (document.body.classList.contains("fixed")) {
    const scrollY = document.body.style.top;
    document.body.classList.remove("fixed", "w-full");
    document.body.style.top = "";
    window.scrollTo(0, parseInt(scrollY || "0") * -1);
  }
};

const hideModal = () => {
  emit("closeModal");
  refocusLastActive();
  bodyUnlock();
};

const refocusLastActive = () => {
  if (initiallyFocusedElement.value) {
    initiallyFocusedElement.value.focus();
  }
};

const saveLastActiveFocus = () => {
  initiallyFocusedElement.value = document.activeElement as HTMLElement;
};

const handleKeyEvent = (event: KeyboardEvent) => {
  if (event.code === "Escape" && props.isOpen) {
    hideModal();
  }
};
</script>
