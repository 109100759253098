<template>
  <span class="block error-text" aria-live="assertive">
    <slot>This field is required</slot>
  </span>
</template>

<style lang="postcss" scoped>
.error-text {
  @apply pt-1;
  @apply text-xs text-error-600;
}
</style>
