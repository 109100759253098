<template>
  <div id="app" class="flex flex-col app-master">
    <div>
      <!-- force a re-rendering when the tenant changes -->
      <DesktopMenu
        v-if="isLoggedIn && !isAnonymising"
        :key="activeTenant"
        class="fixed top-0 z-40 app-menu"
      >
        <template #default="{ isNavOpen }">
          <DesktopMenuItems :is-nav-open="isNavOpen" />
        </template>
      </DesktopMenu>
      <div class="relative flex flex-col flex-1 min-h-screen pb-[120px]">
        <TpfHeader :key="activeTenant" class="pl-[12.5rem] pr-16" />

        <div class="w-full pl-[12.5rem] pr-16">
          <main class="pb-10">
            <router-view v-if="!isSwitchingTenant" />
          </main>
        </div>
        <TpfFooter class="pl-[12.5rem] pr-16 w-full absolute bottom-0 left-0" />
      </div>
    </div>

    <Notification />
  </div>
</template>

<script lang="ts" setup>
import { computed, watch } from "vue";
import { useI18n } from "vue-i18n";

import Notification from "@core/src/components/Notification.vue";
import DesktopMenu from "@shared/src/components/DesktopMenu.vue";
import DesktopMenuItems from "@/components/DesktopMenuItems.vue";
import TpfHeader from "@/components/Header/TpfHeader.vue";
import TpfFooter from "@/components/TpfFooter.vue";

import { useAuthStore } from "@/store/Auth";
import { useBaseStore } from "@/store/Base";
import { useConversationStore } from "@/store/Conversation";

const { t, locale } = useI18n();

const authStore = useAuthStore();
const baseStore = useBaseStore();
const conversationStore = useConversationStore();

const isAnonymising = computed(() => conversationStore.isAnonymising);

const isLoggedIn = computed(() => {
  return authStore.isLoggedIn;
});

const activeTenant = computed(() => {
  return authStore.activeTenant || "activeTenant";
});

/**
 * If we're currently switching the tenant
 *
 * The problem is, that we set the active tenant and then
 * reload the session with this active tenant.
 * In between this, the router-view will be rendered with the
 * already-new tenant, resulting in requests with the new tenant, before the
 * session was reloaded.
 * Those new request might override the tenant again (i.e. with dossier),
 * thus resulting in the tenant not being switched...
 */
const isSwitchingTenant = computed(() => {
  return baseStore.isSwitchingTenant;
});

watch(
  () => locale.value,
  () => {
    document.title = `stopsmoking - ${t("global.title")}`;
  },
  { immediate: true }
);
</script>
