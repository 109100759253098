<template>
  <div
    v-if="!!unreadCount"
    class="unread-dialog-counter"
    data-testid="unread-dialog-counter"
  >
    {{ unreadCount }}
  </div>
</template>

<script lang="ts" setup>
import { computed, onBeforeUnmount, onMounted, ref, type Ref } from "vue";
import { useAuthStore } from "@/store/Auth";

const authStore = useAuthStore();

const interval: Ref<ReturnType<typeof setInterval> | null> = ref(null);

const unreadCount = computed(() => {
  return authStore.unreadDialogCount;
});

const setIntervalUpdateCount = () => {
  // Every N seconds, update the count of unread dialogs.
  return setInterval(() => {
    authStore.updateUnreadDialogCount();
  }, 5000);
};

onMounted(async () => {
  // Initially update the count of unread dialogs.
  await authStore.updateUnreadDialogCount();
  interval.value = setIntervalUpdateCount();
});

onBeforeUnmount(() => {
  window.clearInterval(interval.value!);
});
</script>

<style lang="postcss" scoped>
.unread-dialog-counter {
  @apply absolute right-1 top-1;
  @apply bg-error-600 text-white;
  @apply px-[5px] rounded-full;
  @apply text-[0.6875rem];
}
</style>
