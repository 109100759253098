<template>
  <div ref="dropdownElem" class="relative inline-block">
    <slot name="toggle" :toggle="toggleDropdown" :is-open="dropdownOpen">
      <button
        class="flex items-center text-base btn btn--primary"
        data-testid="button-dropdown"
        @click="toggleDropdown"
      >
        {{ props.text }}
        <SvgIcon
          name="angle-down-light"
          class="ml-3 text-white transition-transform rotate-0 svg-icon--lg duration-250"
          :class="dropdownOpen ? '!rotate-180' : ''"
        />
      </button>
    </slot>

    <Transition name="fade">
      <div
        v-if="dropdownOpen"
        class="dropdown--content"
        :class="props.isLeft ? 'dropdown--content--left' : ''"
      >
        <slot />
      </div>
    </Transition>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, onUnmounted, type Ref } from "vue";

const props = defineProps({
  text: {
    type: String,
    default: "",
  },
  isLeft: {
    type: Boolean,
    default: false,
  },
});

const dropdownOpen: Ref<boolean> = ref(false);
const dropdownElem: Ref<HTMLDivElement | null> = ref(null);

onMounted(() => {
  document.addEventListener("click", clickListener);
});

onUnmounted(() => {
  document.removeEventListener("click", clickListener);
});

const clickListener = (e: Event) => {
  if (!dropdownElem.value?.contains(e.target as Node)) {
    dropdownOpen.value = false;
  }
};

const toggleDropdown = () => {
  dropdownOpen.value = !dropdownOpen.value;
};

defineExpose({ toggleDropdown });
</script>

<style lang="postcss" scoped>
.dropdown--content {
  @apply absolute right-0 z-20;
  @apply min-w-64 mt-2 px-3 pt-2.5 pb-2;
  @apply bg-white border rounded shadow border-grayTpf-200;

  &.dropdown--content--left {
    @apply left-0 right-auto;
  }
}

.fade-enter-from,
.fade-leave-to {
  @apply -translate-y-2 opacity-0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 250ms cubic-bezier(0.3, 1.1, 0.7, 0.9),
    transform 500ms cubic-bezier(0.3, 1.1, 0.7, 0.9);
}
</style>
