import type ILanguage from "@/interfaces/ILanguage";
import { getGenders, getLanguages, getExpertGroups } from "../services/auth";
import type IGender from "@/interfaces/IGender";
import type IExpertGroup from "@/interfaces/IExpertGroup";
import { defineStore } from "pinia";
import type { RouteLocationNormalized } from "vue-router";
import type IAvailabilityState from "@/interfaces/IAvailabilityState";
import { getAvailabilityStates } from "@/services/availability";
import type INoteOption from "@/interfaces/INoteOption";
import { getNotesOptions } from "@/services/notes";
import { getTenantLanguages } from "@/services/tenant";
import i18n from "@/i18n";

interface State {
  languages: ILanguage[] | null;
  tenantLanguages: ILanguage[] | null;
  tenantLanguagesTenant: string | null;
  genders: IGender[] | null;
  expertGroups: IExpertGroup[] | null;
  previousRoute: RouteLocationNormalized | null;
  isSwitchingTenant: boolean;
  availabilityStates: IAvailabilityState[] | null;
  noteOptions: INoteOption[] | null;
}

export const useBaseStore = defineStore("base", {
  state: (): State => ({
    languages: null,
    tenantLanguages: null,
    tenantLanguagesTenant: null,
    genders: null,
    expertGroups: null,
    previousRoute: null,
    // help to know that we're switching the tenant
    isSwitchingTenant: false,
    availabilityStates: null,
    noteOptions: null,
  }),
  actions: {
    async loadLanguages() {
      if (!this.languages) {
        const languages = await getLanguages();
        this.languages = languages;
      }
    },

    async loadTenantLanguages(activeTenant: string | null) {
      if (
        !this.tenantLanguages ||
        this.tenantLanguagesTenant !== activeTenant
      ) {
        const languages = await getTenantLanguages();
        this.tenantLanguages = languages;
        this.tenantLanguagesTenant = activeTenant;
      }
    },

    async loadGenders() {
      if (!this.genders) {
        const genders = await getGenders();
        this.genders = genders;
      }
    },

    setIsSwitchingTenant(value: boolean) {
      this.isSwitchingTenant = value;
    },

    async loadExpertGroups() {
      if (!this.expertGroups) {
        const expertGroups = await getExpertGroups();
        this.expertGroups = expertGroups;
      }
    },

    async loadNoteOptions(): Promise<void> {
      if (!this.noteOptions) {
        const options = await getNotesOptions();
        this.noteOptions = options;
      }
    },

    setPreviousRoute(previousRoute: RouteLocationNormalized) {
      this.previousRoute = previousRoute;
    },

    setExpertGroups(expertGroups: IExpertGroup[] | null) {
      this.expertGroups = expertGroups;
    },

    async loadAvailabilityStates() {
      if (!this.availabilityStates) {
        this.availabilityStates = await getAvailabilityStates();

        // Normal appointment state
        this.availabilityStates.push({
          id: -1,
          name: i18n.global.t("availability.states.appointment"),
          textColor: "#072e7a", // primary/900
          backgroundColor: "#D4E0FA", // primary/100
          iconColor: "#4F80E0", // primary/300
          iconName: "consulting",
        });

        // Appointment with unavailable counselor state
        this.availabilityStates.push({
          id: -2,
          name: i18n.global.t("availability.states.appointmentUnavailable"),
          textColor: "#370002", // error/900
          backgroundColor: "#FFD8DA", // error/100
          iconColor: "#E01F29", // error/600
          iconName: "warning-circle",
        });
      }
    },

    async reloadTranslatableOptions() {
      this.genders = null;
      this.languages = null;
      this.tenantLanguages = null;
      this.availabilityStates = null;
      this.noteOptions = null;

      // availabilityStates and noteOptions don't need to be loaded
      // here, since they are loaded when they are needed.
      await Promise.all([
        this.loadGenders(),
        this.loadLanguages(),
        this.loadTenantLanguages(this.tenantLanguagesTenant),
      ]);
    },
  },
});
