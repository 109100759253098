<template>
  <a
    :href="mattermostLink"
    target="_blank"
    class="!text-grayTpf-900 no-underline"
  >
    <button class="flex items-center ml-2 text-xl">
      <SvgIcon
        name="compass"
        class="w-6 h-6 text-primary-500 fill-transparent"
        aria-hidden="true"
      />
      <div class="ml-3 text-base text-primary-500">
        {{ $t("general.mattermostLink") }}
      </div>
    </button>
  </a>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { useAuthStore } from "@/store/Auth";
import { useRuntimeEnv } from "@core/src/helpers/environment";

const runtimeEnv = useRuntimeEnv();

const authStore = useAuthStore();

const mattermostLink = computed(() => {
  return `${runtimeEnv.VITE_MATTERMOST_URL}/${authStore.activeTenant}`;
});
</script>
