export function deepClone<T>(obj: T): T {
  // If obj is a primitive
  if (obj === null || typeof obj !== "object") {
    return obj;
  }

  // If obj is an array
  if (Array.isArray(obj)) {
    return obj.map((item: any) => deepClone(item)) as unknown as T;
  }

  // If obj is a Date
  if (obj instanceof Date) {
    return new Date(obj) as unknown as T;
  }

  // If obj is an object
  const copy: Partial<T> = {};
  Object.entries(obj).forEach(([key, value]) => {
    // @ts-ignore
    if (obj === obj[key as keyof T]) {
      // Avoid recursive references
      copy[key as keyof T] = obj as T[keyof T];
    } else {
      copy[key as keyof T] = deepClone(value) as T[keyof T];
    }
  });
  return copy as T;
}
