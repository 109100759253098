import {
  createRouter,
  createWebHistory,
  type RouteLocationNormalized,
} from "vue-router";

import { useAuthStore } from "@/store/Auth";
import { useBaseStore } from "@/store/Base";
import { useConversationStore } from "@/store/Conversation";
import routes from "./routes";

import { setLocale } from "@/i18n";
import { userApi } from "@/services/api";
import { setRedirectLocation } from "@/helpers/redirectUrl";

const router = createRouter({
  history: createWebHistory(import.meta.env.VITE_BASE_URL || ""),
  routes,
});

/**
 * Check the permissions of a user and route
 *
 * @param {object} to The route to navigate to
 */
const isPermitted = (to: RouteLocationNormalized) => {
  if (to.meta.requiresNoAuth) {
    return true;
  }

  const authStore = useAuthStore();
  return authStore.isLoggedIn;
};

router.beforeEach(async (to, from) => {
  const authStore = useAuthStore();
  const conversationStore = useConversationStore();
  const baseStore = useBaseStore();

  // Reset isAnonymising on route change
  if (conversationStore.isAnonymising) {
    conversationStore.setIsAnonymising(false);
  }

  // Set the previous route for any needs
  baseStore.setPreviousRoute(from);

  // If the user is already logged in, let them proceed
  if (authStore.isLoggedIn) {
    return true;
  }

  try {
    // Attempt to fetch user details or validate the session
    const response = await userApi.get("account/counselor/me/");
    const loginData = response.data;

    // Store user details in authStore
    authStore.setId(loginData.id);
    authStore.setCsrfToken(loginData.csrfToken);
    authStore.setUserName({
      firstName: loginData.firstName,
      lastName: loginData.lastName,
    });
    authStore.setEmail(loginData.email);
    authStore.setAlias(loginData.aliasName);
    authStore.setActiveTenant(loginData.activeTenant);
    authStore.setAvailableTenants(loginData.availableTenants);
    authStore.setGroups(loginData.groups);
    authStore.setIsAnsweringPublicDossiers(loginData.isAnsweringPublicDossiers);
    authStore.setIsSecretary(loginData.isSecretary);
    setLocale(loginData.language || "de");
    authStore.setIsLoggedIn(true);

    // The user is authenticated, let them proceed
    return true;
  } catch (error) {
    // If an error occurs (like a 403), assume the user is not logged in
    authStore.setIsLoggedIn(false); // Clear user data in the store

    // If the route they're navigating to requires authentication, redirect to login
    if (!isPermitted(to)) {
      // not permitted, store the current url to redirect to after login
      setRedirectLocation(to);
      return { name: "login" };
    } else {
      // The route does not require authentication, let them proceed
      return true;
    }
  }
});

/**
 * This is a temporary fix to refresh the cache if vite loads dynamic modules,
 * which already have an old version of it stored in the cache.
 * See: https://github.com/vitejs/vite/issues/11804
 **/
router.onError((error: Error, to: RouteLocationNormalized) => {
  if (error.message.includes("Failed to fetch dynamically imported module")) {
    window.location.href = to.fullPath;
    window.location.reload();
  }
});

export default router;
