import { format, isDate } from "date-fns";
import i18n from "@/i18n";

import { de, fr, it } from "date-fns/locale";

const locales = {
  de,
  it,
  fr,
};

export const showDateAndTime = (value: Date | string | null | number) => {
  if (!value) return "";
  if (!isDate(new Date(value))) return "";

  const date = new Date(value);

  const currentLanguage = i18n.global.locale.value || "de";
  const locale = locales[currentLanguage as "de" | "fr" | "it"];

  // otherwise return date
  return (
    format(date, "PP", { locale: locale }) +
    " - " +
    format(date, "H:mm", { locale: locale })
  );
};

export const formatDate = (
  value: Date | string | null | number,
  formatScheme = "dd.MM.yyyy"
) => {
  if (!value) return "";
  if (!isDate(new Date(value))) return "";

  const date = new Date(value);

  const currentLanguage = i18n.global.locale.value || "de";
  const locale = locales[currentLanguage as "de" | "fr" | "it"];
  // otherwise return date
  return format(date, formatScheme, { locale: locale });
};
