<template>
  <header class="py-[1.125rem] bg-white">
    <div class="flex items-center justify-between">
      <div>
        <RouterLink
          class="text-lg text-white no-underline font-sansmedium"
          :to="{ name: 'dashboard' }"
        >
          <SvgIcon name="logo" class="w-full h-16" />
        </RouterLink>
      </div>

      <div class="flex items-center justify-between w-2/3">
        <TenantSwitchButton v-if="isLoggedIn" />

        <MattermostButton v-if="isLoggedIn" />

        <VideoConferenceButton v-if="isLoggedIn" />

        <ProfileButton v-if="isLoggedIn" />

        <div v-if="isLoggedIn">
          <a
            class="flex items-center text-base"
            href="/oidc/logout"
            @click="logout"
          >
            <SvgIcon
              name="logout"
              class="text-primary-500 fill-white svg-icon--lg"
            />
            <span class="ml-3 text-primary-500">
              {{ $t("general.logout") }}
            </span>
          </a>
        </div>
      </div>
    </div>
  </header>
</template>

<script lang="ts" setup>
import { computed } from "vue";

import VideoConferenceButton from "@/components/Header/VideoConferenceButton.vue";
import MattermostButton from "@/components/Header/MattermostButton.vue";
import TenantSwitchButton from "@/components/Header/TenantSwitchButton.vue";
import ProfileButton from "@/components/Header/ProfileButton.vue";

import { useAuthStore } from "@/store/Auth";

const authStore = useAuthStore();

const isLoggedIn = computed(() => {
  return authStore.isLoggedIn;
});

const logout = () => {
  authStore.removeAuth();
};
</script>
