<template>
  <div class="flex flex-row form-input">
    <input
      ref="input"
      :value="value"
      :readonly="readonly"
      class="mr-2 bg-transparent transition-all !outline-none w-full"
      :class="{ 'bg-black': isCopying, 'text-white': isCopying }"
      @change="change"
    />
    <button class="w-6 h-6" @click="copy">
      <SvgIcon
        name="clone"
        class="w-5 h-5 transition-size"
        :class="{ 'w-6': isCopying, 'h-6': isCopying }"
        :title="$t('general.copy')"
      />
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    value: {
      required: true,
      type: String,
    },
  },
  emits: ["change"],
  data() {
    return {
      isCopying: false,
    };
  },
  methods: {
    change(value: Event) {
      this.$emit("change", value);
    },

    copy() {
      this.isCopying = true;
      const input = this.$refs.input as HTMLInputElement;
      input.focus();
      input.select();
      document.execCommand("copy");
      input.blur();

      setTimeout(() => {
        this.isCopying = false;
      }, 300);
    },
  },
});
</script>
