/* eslint-disable vue/multi-word-component-names */
// TODO is this needed?
// import "./polyfills";
//
import { app } from "./app";

import { createPinia } from "pinia";

import i18n from "./i18n";
import router from "./router/";
import * as directives from "./directives";
import { floatingVueOptions } from "./plugins/floatingVue";

// Styles
import "./assets/css/main.postcss";

// Svg sprite support for IE11
import "svgxuse";

// Plugins
import "./plugins/lineclamp";
import "./plugins/ClickOutside";
import "./plugins/vueSafeHtml";

// Global components
import SvgIcon from "@/components/SvgIcon.vue";
import FloatingVue from "floating-vue";

import TpfMultiselect from "@shared/src/components/TpfMultiselect.vue";
import { initializeSentry } from "@shared/src/services/sentry";

import Notifications from "@kyvg/vue3-notification";
import Dialog from "@core/lib/vue3-dialog";
import { loadEnvConfig } from "@core/src/helpers/environment";
import { initializeApi } from "./services/api";

async function initializeApp() {
  const config = await loadEnvConfig(import.meta.env);
  // @ts-ignore
  window.runtimeEnv = config; // Store config in window object for use outside of vue context

  initializeSentry(app, config, router);
  initializeApi(config);

  app.use(createPinia());

  app.use(i18n);
  app.use(router);
  app.use(Notifications);
  // @ts-ignore
  app.use(Dialog, {});

  app.use(FloatingVue, floatingVueOptions);
  app.directive("blur", directives.vBlur);
  app.directive("loading", directives.vLoading);
  app.directive("focus", directives.vFocus);
  // @ts-ignore
  app.component("SvgIcon", SvgIcon);
  // @ts-ignore
  app.component("TpfMultiselect", TpfMultiselect);

  app.provide("config", config);

  app.mount("#app");
}

initializeApp().catch((error) => {
  console.error("Failed to initialize the app:", error);
});
