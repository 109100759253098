import type IDefaultAvailability from "@/interfaces/IDefaultAvailability";
import { userApi } from "./api";
import type IAvailabilityState from "@/interfaces/IAvailabilityState";
import type IAvailabilitySlot from "@/interfaces/IAvailabilitySlot";
import type IDefaultAvailabilityBlock from "@/interfaces/IDefaultAvailabilityBlock";
import type IAvailabilities from "@/interfaces/IAvailabilities";
import type IPersonalSlotAvailability from "@/interfaces/IPersonalSlotAvailability";
import type ITeamSlotAvailability from "@/interfaces/ITeamSlotAvailability";
import type ICounselorName from "@/interfaces/ICounselorName";

const getBlocks = async () => {
  const response = await userApi.get(`availability/block/`);

  return response.data;
};

const orderAvailabilities = (availabilityList: IDefaultAvailability[]) => {
  const weekdays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const defaultAvailabilities: any[] = [];

  // The availabilities are generated in the correct order in django.
  // But if someone deletes entries from the db directly, it could change.
  // So to be safe, we order them after weekdays here again.
  for (let i = 0; i < weekdays.length; i++) {
    defaultAvailabilities[i] = availabilityList.find(
      (obj: any) => obj.weekday === weekdays[i]
    );
  }

  return defaultAvailabilities;
};

const getDefaultAvailability = async () => {
  const response = await userApi.get(`availability/default-availability/`);

  return orderAvailabilities(response.data);
};

const updateAllDefaultAvailability = async (
  blocks: IDefaultAvailabilityBlock[]
): Promise<void> => {
  await userApi.post("availability/default-availability/update_all/", {
    blocks,
  });
};

const getTeamAvailability = async (
  date: Date | string,
  filterParams: string
): Promise<IAvailabilities<ITeamSlotAvailability>[]> => {
  const response = await userApi.get(
    `availability/availability/team/?date=${date}` + filterParams
  );
  return response.data;
};

const saveAvailability = async (id: number, availability: any) => {
  const response = await userApi.patch(
    `availability/default-availability/${id}/`,
    availability
  );

  return response.data;
};

const getAvailabilityStates = async (): Promise<IAvailabilityState[]> => {
  const response = await userApi.get("availability/state/");

  return response.data;
};

const getSlots = async (): Promise<IAvailabilitySlot[]> => {
  const response = await userApi.get("availability/slot/");

  return response.data;
};

const getAvailability = async (
  date: Date | string
): Promise<IAvailabilities<IPersonalSlotAvailability>[]> => {
  const response = await userApi.get(`availability/availability/?date=${date}`);
  return response.data;
};

const setWeekOff = async (monday: Date | string) => {
  const response = await userApi.post(
    "availability/availability/set_week_off/",
    { date: monday }
  );
  return response.data;
};

const resetDefaultAvailabilitiesWeek = async (monday: Date | string) => {
  const response = await userApi.post("availability/availability/reset_week/", {
    date: monday,
  });
  return response.data;
};

const setDayOff = async (day: Date | string) => {
  const response = await userApi.post(
    "availability/availability/set_day_off/",
    { date: day }
  );
  return response;
};

const resetDefaultAvailabilitiesDay = async (day: Date | string) => {
  const response = await userApi.post("availability/availability/reset_day/", {
    date: day,
  });
  return response;
};

const setState = async (day: Date | string, slot: number, state: number) => {
  const response = await userApi.post("availability/availability/", {
    slot: slot,
    date: day,
    state: state,
  });
  return response.data;
};

const getCounselors = async (filters?: {
  language?: number;
}): Promise<ICounselorName[]> => {
  const params = new URLSearchParams();

  if (filters?.language) {
    params.set("language", filters.language.toString());
  }

  const response = await userApi.get(`account/counselor/?${params.toString()}`);
  return response.data;
};

export {
  getBlocks,
  getDefaultAvailability,
  updateAllDefaultAvailability,
  saveAvailability,
  getTeamAvailability,
  getAvailabilityStates,
  getAvailability,
  getSlots,
  setWeekOff,
  resetDefaultAvailabilitiesWeek,
  setDayOff,
  resetDefaultAvailabilitiesDay,
  setState,
  getCounselors,
};
