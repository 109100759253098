<template>
  <OverlaySkeleton v-loading="isLoading">
    <template #header>{{ $t("login.footer.sms.title") }}</template>

    <div class="mb-12">
      <FormInput
        v-model="state.phoneNumber"
        :label="$t('login.footer.sms.phoneNumber')"
        :placeholder="$t('login.footer.sms.phoneNumber')"
        :error="v$.phoneNumber.$error"
        class="relative"
        class-input="w-full pr-12"
        required
        is-block
        @keypress="phoneNumberKeypress"
        @blur="v$.phoneNumber.$touch"
      >
        <SvgIcon
          class="absolute bottom-3 right-3 svg-icon--lg"
          name="phone-outline"
        />
      </FormInput>
      <template v-if="v$.phoneNumber.$error">
        <ValidationText v-if="v$.phoneNumber.required.$invalid">
          {{ $t("login.footer.sms.required") }}
        </ValidationText>
        <ValidationText v-else>
          {{ $t("login.footer.sms.invalidPhoneNumber") }}
        </ValidationText>
      </template>

      <FreeTextField
        v-model="state.message"
        class="w-full mt-6"
        class-input="w-full"
        class-label="!block"
        :label="$t('login.footer.sms.message')"
        :placeholder="$t('login.footer.sms.message')"
        :error="v$.message.$error"
        required
        is-block
        is-expanded
        :max="1500"
        @blur="v$.message.$touch"
      />
      <ValidationText v-if="v$.message.$error">
        {{ $t("login.footer.sms.required") }}
      </ValidationText>
    </div>

    <template #buttons>
      <button class="mr-16 btn btn--tertiary" @click="cancel">
        {{ $t("general.cancel") }}
      </button>

      <button
        class="btn btn--primary"
        :disabled="v$.$invalid"
        @click="sendMessage"
      >
        {{ $t("login.footer.sms.title") }}
      </button>
    </template>
  </OverlaySkeleton>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { notify } from "@kyvg/vue3-notification";

import FormInput from "@shared/src/components/FormInput.vue";
import FreeTextField from "@shared/src/components/FreeTextField.vue";
import ValidationText from "@shared/src/components/ValidationText.vue";
import OverlaySkeleton from "@/components/OverlaySkeleton.vue";

import {
  phoneNumberValidator,
  phoneNumberKeypress,
} from "@shared/src/helpers/phoneNumber";
import { sendSMS } from "@/services/sms";

const { t } = useI18n();

const emit = defineEmits(["close"]);

const isLoading = ref(false);

const state = ref({
  phoneNumber: "",
  message: "",
});

const rules = {
  phoneNumber: { phoneNumber: phoneNumberValidator, required },
  message: { required },
};

const v$ = useVuelidate(rules, state);

const cancel = () => emit("close");

const sendMessage = async () => {
  isLoading.value = true;
  try {
    await sendSMS(state.value.phoneNumber, state.value.message);
    notify({
      type: "success",
      text: t("login.footer.sms.success"),
    });
    emit("close");
  } catch {
    notify({
      type: "error",
      text: t("login.footer.sms.error"),
    });
  }
  isLoading.value = false;
};
</script>
