import type IDossierProfile from "@/interfaces/IDossierProfile";

const parseProfilePayload = (
  profile: Partial<IDossierProfile>
): Partial<IDossierProfile> => {
  // ensure phone / mobile has no null values
  if (!profile.mobilePhone) {
    profile.mobilePhone = "";
  }
  if (!profile.telephone) {
    profile.telephone = "";
  }
  return profile;
};

export { parseProfilePayload };
