import type IDossierGoalsData from "@/interfaces/IDossierGoalsData";
import type IGoalsDataOption from "@/interfaces/IGoalsDataOption";
import type IDossierGoalsDataPayload from "@/interfaces/IDossierGoalsDataPayload";
import type IGoalsMilestonePayload from "@/interfaces/IDossierGoalsDataPayload";

import { messagingApi } from "@/services/api";
import { formatDate } from "@/filters/date";

// ! duplicate in client-frontend !

const getGoalData = async (goalsId: number): Promise<IDossierGoalsData> => {
  const response = await messagingApi.get(`counseling/goals/${goalsId}/`);
  return response.data;
};

const updateGoalData = async (
  goalsId: number,
  goalsData: Partial<IDossierGoalsData>
): Promise<IDossierGoalsData> => {
  const data = parseGoalDataPayload(goalsData);
  const response = await messagingApi.patch(
    `counseling/goals/${goalsId}/`,
    data
  );
  return response.data;
};

const getGoalDataOptions = async (): Promise<IGoalsDataOption[]> => {
  const response = await messagingApi.get("counseling/goals-option/");
  return response.data;
};

const parseGoalDataPayload = (
  goalData: Partial<IDossierGoalsData>
): IDossierGoalsDataPayload => {
  const data = { ...goalData, exitDate: undefined } as IDossierGoalsDataPayload;
  const mileStoneData = goalData.milestones as IGoalsMilestonePayload[];
  if (goalData.exitDate) {
    // Format date as YYYY-MM-DD
    data.exitDate = formatDate(goalData.exitDate, "yyyy-MM-dd");
  }
  if (mileStoneData && mileStoneData.length > 0) {
    mileStoneData.forEach((milestone, index) => {
      if (!goalData.milestones) return data;
      if (milestone.exitDate) {
        mileStoneData[index].exitDate = formatDate(
          goalData.milestones[index].exitDate,
          "yyyy-MM-dd"
        );
      }
      // Remove id if not set, so that the backend creates them. Otherwise update!
      if (!milestone.id) {
        delete mileStoneData[index].id;
      }
    });
  }
  return data;
};

export {
  getGoalData,
  updateGoalData,
  getGoalDataOptions,
  parseGoalDataPayload,
};
