import axios, {
  type AxiosInstance,
  type AxiosError,
  type InternalAxiosRequestConfig,
} from "axios";
import { useAuthStore } from "@/store/Auth";
import router from "../router";
import i18n from "@/i18n";

let userApi: AxiosInstance;
let messagingApi: AxiosInstance;

const loginInterceptor = (config: InternalAxiosRequestConfig<any>) => {
  config.headers["Accept-Language"] = i18n.global.locale.value;
  return config;
};

const csrfInterceptor = (config: InternalAxiosRequestConfig<any>) => {
  if (
    config.method &&
    ["post", "put", "patch", "delete"].includes(config.method)
  ) {
    const authStore = useAuthStore();
    config.headers["X-CSRFToken"] = authStore.csrfToken;
  }
  return config;
};

const tenantInterceptor = (
  config: InternalAxiosRequestConfig<any>
): InternalAxiosRequestConfig<any> => {
  const authStore = useAuthStore();
  if (config.method && ["post", "put", "patch"].includes(config.method)) {
    if (!config.data) {
      config.data = {
        tenant: authStore.activeTenant,
      };
    } else {
      config.data["tenant"] = authStore.activeTenant;
    }
  } else {
    if (!config.params) {
      config.params = {
        tenant: authStore.activeTenant,
      };
    } else {
      config.params["tenant"] = authStore.activeTenant;
    }
  }

  return config;
};

const groupInterceptor = (
  config: InternalAxiosRequestConfig<any>
): InternalAxiosRequestConfig<any> => {
  const authStore = useAuthStore();
  if (config.method && ["post", "put", "patch"].includes(config.method)) {
    if (!config.data) {
      config.data = {
        group: authStore.activeGroup?.id,
      };
    } else {
      config.data["group"] = authStore.activeGroup?.id;
    }
  } else {
    if (!config.params) {
      config.params = {
        group: authStore.activeGroup?.id,
      };
    } else {
      config.params["group"] = authStore.activeGroup?.id;
    }
  }

  return config;
};

const errorHandler = (error: AxiosError) => {
  if (error.response?.status === 401) {
    router.push({ name: "login" });
  }
  return Promise.reject(error);
};

export function initializeApi(config: any) {
  userApi = axios.create({
    baseURL: config.VITE_API_URL,
    withCredentials: true,
  });

  messagingApi = axios.create({
    baseURL: config.VITE_API_URL,
    withCredentials: true,
  });

  const passThrough = (response: any) => response;

  messagingApi.interceptors.response.use(passThrough, errorHandler);
  messagingApi.interceptors.request.use(loginInterceptor);
  messagingApi.interceptors.request.use(csrfInterceptor);
  messagingApi.interceptors.request.use(tenantInterceptor);
  messagingApi.interceptors.request.use(groupInterceptor);

  userApi.interceptors.request.use(loginInterceptor);
  userApi.interceptors.request.use(csrfInterceptor);
  userApi.interceptors.request.use(tenantInterceptor);
  userApi.interceptors.request.use(groupInterceptor);
  userApi.interceptors.response.use(passThrough, errorHandler);
}

export { userApi, messagingApi };
