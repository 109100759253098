import LoadingSVG from "./assets/icons/loading.svg?raw";
import type { Directive, DirectiveBinding } from "vue";

const addLoading = (el: HTMLElement, fixed: boolean) => {
  if (!el.querySelectorAll(".loading-directive").length) {
    const classes = `loading-directive ${fixed ? "!fixed" : ""}`;
    el.insertAdjacentHTML(
      "afterbegin",
      `<div class="${classes}"><div><div>${LoadingSVG}</div></div></div>`
    );
  }
};

const removeLoading = (el: HTMLElement) => {
  el.classList.remove("loading-directive__container");
  el.querySelectorAll(".loading-directive").forEach((value: Element) => {
    value.remove();
  });
};

/**
 * Toggle the loader
 *
 * @param {*} el
 * @param {*} binding
 */
const toggleLoading = (el: HTMLElement, binding: DirectiveBinding<boolean>) => {
  const value = binding.value;
  const fixed = binding.arg === "fixed";

  if (value) {
    addLoading(el, fixed);
  } else {
    removeLoading(el);
  }
};

/**
 * v-loading="true" => always show loading spinner
 * v-loading:fixed="true" => show fixed loading spinner
 */
export const vLoading: Directive<HTMLElement, boolean> = {
  beforeMount(el, binding) {
    toggleLoading(el, binding);
  },
  updated(el, binding) {
    toggleLoading(el, binding);
  },
  unmounted(el) {
    removeLoading(el);
  },
};

export const vFocus: Directive<HTMLElement, boolean> = {
  mounted(el, binding) {
    if (
      !Object.prototype.hasOwnProperty.call(binding, "value") ||
      binding.value
    ) {
      el.focus();
    }
  },
};

export const vBlur: Directive<HTMLElement, undefined> = {
  mounted(el) {
    el.addEventListener("click", () => {
      el.blur();
    });
  },
};
