import { isValidPhoneNumber } from "libphonenumber-js";

const phoneNumberValidator = (value: string) => {
  // Check if the phone number is valid using libphonenumber-js
  return !value || isValidPhoneNumber(value);
};

const phoneNumberKeypress = (event: KeyboardEvent) => {
  // Allow only numbers and plus sign, no spaces
  if (!/[\d+]/.test(event.key)) {
    event.preventDefault();
  }
};

export { phoneNumberValidator, phoneNumberKeypress };
