import { userApi } from "./api";

/**
 * Send a SMS
 */
const sendSMS = async (phoneNumber: string, message: string) => {
  const response = await userApi.post("sms/send/", {
    phoneNumber,
    message,
  });

  return response.data;
};

export { sendSMS };
