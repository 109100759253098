import type ITenant from "@/interfaces/ITenant";
import type IPermissionGroup from "@/interfaces/IPermissionGroup";
import { defineStore } from "pinia";
import { getUnreadDialogCount } from "@/services/counseling";
import EventBus, { EVENT_ACTIVE_GROUP_CHANGED } from "@/globals/EventBus";

export type Tenant = {
  name: string;
  tag: string;
};

const PermissionGroups = {
  TENANT_ADMIN_GROUP: 2,
  COUNSELOR_GROUP: 3,
  // TBD: If needed in the frontend for switching roles in the future.
  // MENTOR_GROUP: 4,
  // SUPER_COUNSELOR_GROUP: 1,
  // PUBLIC_DOSSIER_GROUP: 5,
  // SECRETARY_GROUP: 6,
  // COUNSELOR_GROUPS: [COUNSELOR_GROUP, MENTOR_GROUP, SUPER_COUNSELOR_GROUP]
};

interface State {
  id: number | null;
  csrfToken: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  alias: string | null;
  activeTenant: string | null;
  availableTenants: Array<Tenant> | null | undefined;
  isLoggedIn: boolean;
  tenantData: ITenant | null;
  isAnsweringPublicDossiers: boolean;
  isSecretary: boolean;
  groups: Array<IPermissionGroup> | null;
  activeGroup: IPermissionGroup | null;
  unreadDialogCount: Number | null;
}

export const useAuthStore = defineStore("auth", {
  state: (): State => ({
    id: null,
    csrfToken: null,
    firstName: null,
    lastName: null,
    email: null,
    alias: null,
    activeTenant: null,
    availableTenants: null,
    isLoggedIn: false,
    tenantData: null,
    isAnsweringPublicDossiers: false,
    isSecretary: false,
    groups: null,
    activeGroup: null,
    unreadDialogCount: null,
  }),
  getters: {
    tenantHasAdditionalFields(state) {
      return (state.tenantData?.additionalFields || []).length > 0;
    },

    tenantAdditionalFields(state) {
      if (this.tenantHasAdditionalFields) {
        return state.tenantData?.additionalFields;
      }
      return [];
    },
    isTenantAdmin(state) {
      return state.activeGroup?.id === PermissionGroups.TENANT_ADMIN_GROUP;
    },
    isTenantAdminOrCounselor(state) {
      return (
        state.activeGroup?.id === PermissionGroups.TENANT_ADMIN_GROUP ||
        state.activeGroup?.id === PermissionGroups.COUNSELOR_GROUP
      );
    },
    hasTenantAdminRole(state): boolean {
      return (
        state.groups?.find((obj: IPermissionGroup) => {
          return obj.id === PermissionGroups.TENANT_ADMIN_GROUP;
        }) !== undefined
      );
    },
    isCounselor(state) {
      return state.activeGroup?.id === PermissionGroups.COUNSELOR_GROUP;
    },
  },
  actions: {
    setUserName({
      firstName,
      lastName,
    }: {
      firstName: string;
      lastName: string;
    }) {
      this.firstName = firstName;
      this.lastName = lastName;
    },

    async restoreSession() {
      const groupNameString = sessionStorage.getItem("activeGroup");
      // Reset selected group from session storage on page reload
      if (groupNameString) {
        const group = JSON.parse(groupNameString);
        this.setActiveGroup(group);
      }
    },

    setAlias(alias: string) {
      this.alias = alias;
    },

    setActiveTenant(activeTenant: string) {
      this.activeTenant = activeTenant;
    },

    setAvailableTenants(availableTenants: Array<Tenant>) {
      this.availableTenants = availableTenants;
    },

    setId(id: number) {
      this.id = id;
    },

    setEmail(email: string) {
      this.email = email;
    },

    setCsrfToken(csrfToken: string) {
      this.csrfToken = csrfToken;
    },

    setIsLoggedIn(isLoggedIn: boolean) {
      this.isLoggedIn = isLoggedIn;
    },

    setTenantData(tenantData: ITenant) {
      this.tenantData = tenantData;
    },

    setIsAnsweringPublicDossiers(isAnsweringPublicDossiers: boolean) {
      this.isAnsweringPublicDossiers = isAnsweringPublicDossiers;
    },

    setIsSecretary(isSecretary: boolean) {
      this.isSecretary = isSecretary;
    },

    setGroups(groups: Array<IPermissionGroup>) {
      this.groups = groups;

      // check if the user switched to another role
      let group: IPermissionGroup | null = null;
      const groupFromStorage = sessionStorage.getItem("activeGroup");
      if (groupFromStorage) {
        group = JSON.parse(groupFromStorage);
      }
      this.setActiveGroup(group);
    },

    /**
     * Used to set a newly active permission group.
     * Used to initially set your permission group, based on if you're a counselor or not.
     * Used to get saved permission group on page reload.
     * @param {IPermissionGroup | null} group - The permission group which needs to be set.
     */
    setActiveGroup(group: IPermissionGroup | null = null) {
      if (!this.groups && !group) return;
      // Reset active role with user inputs
      // check if the user is a member of the given group. if not reset active group
      if (group && this.groups) {
        const activeGroupInGroups =
          this.groups.findIndex((g: IPermissionGroup) => g.id === group.id) >
            -1 || false;
        if (!activeGroupInGroups) {
          this.activeGroup = null;
          sessionStorage.removeItem("activeGroup");
        } else {
          this.activeGroup = group;
          sessionStorage.setItem("activeGroup", JSON.stringify(group));
          // Trigger update count of unread dialogs after changing the active group.
          this.updateUnreadDialogCount();
          EventBus.$emit(EVENT_ACTIVE_GROUP_CHANGED);
          return;
        }
      }

      // Initially set active group
      // If group counselor is in list we will always select it as default.
      const counselorGroup = this.groups?.find(
        (group) => group.id === PermissionGroups.COUNSELOR_GROUP
      );
      if (counselorGroup) {
        this.activeGroup = counselorGroup;
        return;
      }
      // If not, take the first of list.
      this.activeGroup = this.groups ? this.groups[0] : null;
    },

    removeAuth() {
      this.isLoggedIn = false;
      this.firstName = null;
      this.lastName = null;
      // removing this quickly triggers a rerender, which results
      // in loading the current view again on logout
      //this.activeTenant = null;
      this.availableTenants = null;
      this.alias = null;
      this.groups = null;
      this.activeGroup = null;

      sessionStorage.clear();
    },

    async updateUnreadDialogCount() {
      this.unreadDialogCount = await getUnreadDialogCount();
    },
  },
});
