<template>
  <div class="flex flex-col h-full">
    <div class="mb-10">
      <h2 class="text-2xl text-primary-500">
        <slot name="header" />
      </h2>
    </div>

    <div><slot /></div>

    <div class="flex pt-6 mt-auto border-t border-grayTpf-300 wizard-buttons">
      <slot name="buttons"></slot>
    </div>
  </div>
</template>
