import type IAutoResponse from "@/interfaces/IAutoResponse";
import type ICounselor from "@/interfaces/ICounselor";
import type IProfile from "@/interfaces/IProfile";
import { userApi } from "./api";

/**
 * Perform a login
 *
 * @return User Info
 */
const login = async (
  email: string,
  password: string,
  twoFactorToken: string
) => {
  const response = await userApi.post("account/token/", {
    email: email,
    password: password,
    token: twoFactorToken ? twoFactorToken : null,
  });

  return response;
};

const updateClient = async (
  id: number,
  gender: number,
  additionalFieldAnswers: any
) => {
  const response = await userApi.put(`account/client/${id}/`, {
    gender,
    additionalFieldAnswers,
  });

  return response.data;
};

const getLanguages = async () => {
  const response = await userApi.get(`account/language/`);

  return response.data;
};

const getMentors = async () => {
  const response = await userApi.get(`account/mentor/`);

  return response.data;
};

const getGenders = async () => {
  const response = await userApi.get(`account/gender/`);

  return response.data;
};

const getProfile = async (id: number): Promise<IProfile> => {
  const response = await userApi.get(`account/profile/${id}/`);

  return response.data;
};

const saveProfile = async (id: number, profile: Partial<IProfile>) => {
  const response = await userApi.put(`account/profile/${id}/`, profile);

  return response.data;
};

const partialSaveProfile = async (id: number, profile: Partial<IProfile>) => {
  const response = await userApi.patch(`account/profile/${id}/`, profile);

  return response.data;
};

const activate2FA = async (code: string) => {
  const response = await userApi.post(`account/activate-2fa/`, { code: code });

  return response.data;
};

const deactivate2FA = async () => {
  const response = await userApi.post(`account/deactivate-2fa/`);

  return response.data;
};

const getTwoFactorUrl = async () => {
  const response = await userApi.get(`account/2fa-url/`);
  return response.data;
};

const resetPassword = async (
  password: string,
  token: string,
  email: string
) => {
  try {
    const response = await userApi.post(`account/reset-password/reset/`, {
      password,
      token,
      email,
    });

    return response.data;
  } catch (error: any) {
    if (error.response.status == 400) {
      throw error.response.data;
    } else {
      throw error;
    }
  }
};

const forgotPassword = async (email: string) => {
  const response = await userApi.post(`account/reset-password/email/`, {
    email,
  });

  return response.data;
};

const getAutoResponse = async (userId: number): Promise<IAutoResponse> => {
  const response = await userApi.get(`account/auto-response/${userId}/`);

  return response.data;
};

const saveAutoResponse = async (
  userId: number,
  data: IAutoResponse
): Promise<IAutoResponse> => {
  const response = await userApi.put(`account/auto-response/${userId}/`, data);

  return response.data;
};

const partialSaveAutoResponse = async (
  userId: number,
  data: Partial<IAutoResponse>
): Promise<IAutoResponse> => {
  const response = await userApi.patch(
    `account/auto-response/${userId}/`,
    data
  );

  return response.data;
};

const getCounselors = async (filter: string): Promise<ICounselor[]> => {
  const response = await userApi.get(`account/counselors/?${filter}`);

  return response.data;
};

const getExpertGroups = async () => {
  const response = await userApi.get(`account/expert-group/`);

  return response.data;
};

export {
  login,
  updateClient,
  getLanguages,
  getProfile,
  saveProfile,
  partialSaveProfile,
  getGenders,
  getMentors,
  activate2FA,
  deactivate2FA,
  getTwoFactorUrl,
  resetPassword,
  forgotPassword,
  getAutoResponse,
  saveAutoResponse,
  partialSaveAutoResponse,
  getCounselors,
  getExpertGroups,
};
