import { computed, type ComputedRef } from "vue";

import { useI18n } from "vue-i18n";

export interface Label {
  "counseling-archive": string;
  home: string;
  "counselor-list": string;
  mattermost: string;
  dashboard: string;
}

export function useNavigationLabels() {
  const { t } = useI18n();

  /**
   * These labels get used by the breadcrum too, so the names
   * have to be equal to the route name
   */
  const labels: ComputedRef<Label> = computed(() => {
    return {
      "counseling-archive": consultationArchiveLabel.value,
      home: consultationsLinkLabel.value,
      "counselor-list": counselorListLabel.value,
      mattermost: mattermostLabel.value,
      dashboard: dashboardLinkLabel.value,
    };
  });

  const dashboardLinkLabel = computed(() => {
    return t("navigation.dashboard").toString();
  });

  const consultationsLinkLabel = computed(() => {
    return t("navigation.counselings").toString();
  });

  const consultationArchiveLabel = computed((): string => {
    return t("navigation.counselingArchive").toString();
  });

  const counselorListLabel = computed((): string => {
    return t("navigation.counselorList").toString();
  });

  const mattermostLabel = computed((): string => {
    return t("general.mattermost").toString();
  });

  return { labels, consultationsLinkLabel };
}
