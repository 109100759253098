/**
 * Compares the current object with a saved initial state to check if any changes where made.
 * Due to how input fields work, if the null => "" is not detected as a change.
 *
 * Does not support arrays.
 *
 * @returns true, if there are changes between current and initial.
 */
export const hasObjectedChanged = <T extends object & { length?: number }>(
  current: T,
  initial: T
): boolean => {
  if (!current || !initial) {
    return false;
  }

  for (const [key, value] of Object.entries<any>(current)) {
    const initialValue = initial[key as keyof T];
    if (Array.isArray(value)) {
      if (value.length != (initialValue as unknown as any[]).length) {
        return true;
      } else if (
        // Check date in each milestone
        value.some((item, i) => {
          return item?.exitDate !== (initialValue as any[])[i]?.exitDate;
        })
      ) {
        return true;
      }

      if (
        value.some((item, i) => {
          if (typeof item === "object") {
            return hasObjectedChanged(
              item,
              (initialValue as unknown as any[])[i]
            );
          } else {
            return !(initialValue as unknown as any[]).includes(item);
          }
        })
      ) {
        return true;
      }
    } else if (typeof value === "object") {
      if (hasObjectedChanged(value, initialValue)) {
        return true;
      } else if (initialValue != value) {
        // Compare date string with date object
        return true;
      }
    } else if (value !== initialValue && !(value === "" && !initialValue)) {
      return true;
    }
  }
  return false;
};
